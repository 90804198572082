.footer {
    background-color: #014a97 !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
    z-index: 2;
}
.location_image{
    width:16px;
    margin-bottom: -50px;
}
.subaddress{
    color:#FFFFFF;
    margin-left:30px;
}
.address{
    color:#FFFFFF;
    margin-top:20px;
    margin-left:5px;
}
.location_image{
    width:20px;
    margin-left:3px;
}
.footerLogo img {
    position: relative;
    top: 70px;
}

.footer h2 {
    color: #FFFFFF;
    font-family: "Helvetica", Sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.borderImage {
   margin-top: -30px;
}

.quick-link ul li {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-type: none;
    margin-left: -20px;
}

.quick-link ul li a {
    color: #FFFFFF;
    font-weight: 600;
    font-family: 'Helvetica'!important;
}

.emailIcon {
    color: #FFFFFF;
}

.linkedinIcon {
    color: #014a97;
    background-color: #FFFFFF;
    padding: 11px 10px;
    border-radius: 50%;
    margin: 5px;
    font-size: 20px;
}

.twitterIcon {
    color: #014a97;
    background-color: #FFFFFF;
    padding: 11px 10px;
    border-radius: 50%;
    margin: 5px;
    font-size: 20px;
}

.youTubeIcon {
    color: #014a97;
    background-color: #FFFFFF;
    padding: 11px 10px;
    border-radius: 50%;
    margin: 5px;
    font-size: 20px;
}


/* Responsive-Start */

@media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
  }
  
  @media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
  }
  
  @media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
  }
  
  @media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
    .footer {
        padding: 10px 0px 50px 0px;
        z-index: 2;
        padding-left: 20px;
    }
    .footerLogo img {
        position: relative;
        top: 20px;
    }
    .quick-link {
        padding-top: 40px;
    }
}

  @media only screen and (max-device-width: 768px) {
  /* default iPad screens */
    .footer {
        padding: 10px 0px 50px 0px;
        z-index: 2;
        padding-left: 20px;
    }
    .footerLogo img {
        position: relative;
        top: 15px;
    }
    .quick-link {
        padding-top: 40px;
    }

    .linkedinIcon{
        color: #014a97;
        background-color: #FFFFFF;
        padding: 10px 10px;
        border-radius: 50%;
        margin: 5px;
        font-size: 20px;
    }

    .twitterIcon{
        color: #014a97;
        background-color: #FFFFFF;
        padding: 10px 10px;
        border-radius: 50%;
        margin: 5px;
        font-size: 20px;
    }
    
    .youTubeIcon{
        color: #014a97;
        background-color: #FFFFFF;
        padding: 10px 10px;
        border-radius: 50%;
        margin: 5px;
        font-size: 20px;
    }
}
  
  @media only screen and (max-width: 1024px) {
    /* styles for browsers larger than 960px; */

  }
  
  
  /* different techniques for iPad screening */
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
  }
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
  }

.header {
  background-color: #fff !important;
  color: #fff;
  box-shadow: 0px 0px 2px #000;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}

.header .navbar-brand img {
  width: 250px !important;
  position: absolute;
  top: 10px;
}

/* .navbar-expand-lg .navbar-nav {
  padding-top: 18px;
  padding-bottom: 17px;
} */

.navbar-light .navbar-nav .nav-link a {
  font-size: 18px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  font-family: 'Helvetica'!important;
  transition: .5 ease-in-out;
  
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link a:hover {
  color: #014A97 !important;
}

.nav-link {
  color: black !important;
}

.main-nav-active {
  color: #014A97 !important;
  font-weight: 700;
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
}

@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}

@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

@media only screen and (max-device-width: 480px) {
/* styles for mobile browsers smaller than 480px; (iPhone) */
  .header {
    padding-bottom: 35px;
  }
  .navbar-light .navbar-toggler {
    margin-top: -10px;
  }
  .navbar-collapse {
    background: #fff;
    z-index: 100;
    margin-top: 20px;
    margin-bottom: -32px !important;
  }
  .header .navbar-brand img {
    width: 100px !important;
    position: absolute;
    top: 0px;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid #000;
  }
  .navbar-light .navbar-nav .nav-link:last-child {
    border-bottom: 0px solid #000;
  }
}

@media only screen and (max-device-width: 768px) {
/* default iPad screens */
  .header {
    padding-bottom: 35px;
  }
  .navbar-light .navbar-toggler {
    margin-top: -10px;
  }
  .navbar-collapse {
    background: #fff;
    z-index: 100;
    margin-top: 50px;
    margin-bottom: -32px !important;
  }
  .header .navbar-brand img {
    width: 210px !important;
    position: absolute;
    top: 5px;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid #000;
  }
  .navbar-light .navbar-nav .nav-link:last-child {
    border-bottom: 0px solid #000;
  }
}

@media only screen and (max-width: 1024px) {
  /* styles for browsers larger than 960px; */
  .navbar-collapse {
    background: #fff;
    z-index: 100;
  }
}


/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
}